import { LangType } from '@Types/LangType';

declare const Lang: LangType;

const Language = {
  get(key: any): any {
    if (Lang.has(`strings.${key}`)) return Lang.get(`strings.${key}`);
    return key;
  },
};

export default Language;
